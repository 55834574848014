import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import BirthdayPartiesSlider from '../components/pages/BirthdayPartiesSlider';
import whyhostimg from '../images/whyhostimg.jpg';
import expectimg2 from '../images/expectimg2.jpg';
import LineFull from '../images/linefull.jpg';
import PriceLine from '../images/pricline.png';
import HireDJ from '../images/hiredjimg.jpg';
import HirePhoto from '../images/hirephotoimg.jpg';
import SetupImg from '../images/setupimg.jpg';
import WifiStar from '../images/wifistar.png';
import GreenLogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const BirthDay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = data.wordpressPage.yoast_title;
    //const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt" id="bookparty">
                <div className="container">
                    <h2 className="bluetxt">BOOK YOUR BIRTHDAY PARTY!</h2>

                    <div className="bookawrap">
                        <a
                            href={`tel:${post.acf.party_call_us_number}`}
                            className="wrtsbtn yellowbtn fullbtn"
                        >
                            CALL US TODAY: {post.acf.party_call_us_number}
                        </a>
                    </div>
                    <img src={LineFull} alt="linefull" />
                </div>
            </section>

            <section
                className="page-section smallestwdt respitesec notoppaddsec"
                id="toplftcarous"
            >
                <div className="container">
                    <div className="openplft">
                        <div
                            id="myCarousel3"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <BirthdayPartiesSlider
                                galleryImages={post.acf.bp_gallery}
                            />
                        </div>
                        <div className="bdaybtns">
                           <iframe
                            src="https://werockthespectrumgeelongym.wrtsfranchise.com/birthday-parties-private-playdates/"
                            title="register"
                            className="healcode_widgets"
                        />
                        </div>

                        <div
                            className="display_none"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_book_online_code_widget,
                            }}
                        />
                    </div>

                    <div className="openprgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_title,
                            }}
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: post.acf.bp_content,
                            }}
                        />
                    </div>
                </div>
            </section>

    
            <section className="page-section centersec notoppaddsec">
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_title,
                        }}
                    />


                    {/* <img className="bp-pricing-img" src="https://werockthespectrumgeelongym.wrtsfranchise.com/wp-content/uploads/2021/12/partygeelong.jpg" alt="pricing box" /> */}

                    <div className="iframe-bp-package">

                        {/* <iframe src="https://www.canva.com/design/DAF-ORZYFO4/view?embed"

                        /> */}
                        <iframe src="https://www.canva.com/design/DAGF6gTz2pM/bgulykc04tr2Ss6Foq_0gw/view?embed"

                        />

                    </div>

                    <p
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_pricing_description,
                        }}
                    />

                    <img className="linemar" src={LineFull} alt="linefull" />

                    <div className="packagesdiv">
                        {/* {post.acf.bp_pricing_list.map((item, i) => (
                            <div
                                className="pricingbox bg-primary text-white"
                                key={i}
                            >
                                <h2>
                                    <span
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: item.bp_price_title,
                                        }}
                                    />
                                    <br />
                                    <span className="smallerprice">{item.bp_price_ammount}</span>
                                </h2>
                                <img src={PriceLine} alt="priceline" />
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: item.bp_price_package_content,
                                    }}
									
                                />
								
                            </div>
                        ))} */}
						<div className="bdayaddons">
						<h5 className="biggertxt lastitem txt039 bluetxt">Birthday Add-Ons</h5> 
						<h5 className="bluetxt">Contact us to discuss your individual needs</h5>		
						</div>	
                    </div>	
                   
                </div>
				
	 


            </section>
            

            <section className="page-section centersec bdayprivplaydates">
                <div className="container smallestwdt">
                    <h2
                        className="bluetxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_private_playdates_title,
                        }}
                    />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: post.acf.bp_private_playdates_content,
                        }}
                    />

                    <a
                        href={`tel:${post.acf.party_call_us_number}`}
                        className="wrtsbtn yellowbtn inlinebtn"
                    >
                        BOOK YOUR PRIVATE PLAYDATE! CALL US TODAY:{' '}
                        {post.acf.party_call_us_number}
                    </a>
                    <img className="linemar" src={LineFull} alt="linefull" />

                    <div className="greenlft">
                        <img src={GreenLogo} alt="greenlogo" />
                    </div>

                    <div className="greenrgt">
                        <h2 className="bluetxt">We Use Only Green Products</h2>
                        <p className="biggertxt lastitem">
                            We believe in keeping our precious ones healthy and
                            safe. Our gym is cleaned daily at the end of the day
                            with only green products.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default BirthDay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                bp_book_online_code_widget
                bp_content
                bp_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_content
                bp_hire_a_dj_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_hire_a_dj_title
                bp_hire_photographer_content
                bp_hire_photographer_title
                bp_open_play_unlimited_link
                bp_pricing_description
                bp_pricing_title
                bp_private_playdates_title
                bp_private_playdates_content
                bp_set_open_air_content
                bp_title
                bp_set_open_air_title
                bp_what_to_expect_content
                bp_why_title
                bp_why_reasons
                bp_what_to_expect_title
                bp_hire_photographer_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_pricing_list {
                    bp_price_ammount
                    bp_price_package_content
                    bp_price_title
                }
                bp_set_open_air_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                bp_what_to_expect_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                party_call_us_number
                bp_why_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }

        }
    }
`;
